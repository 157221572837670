/* eslint-disable no-unused-vars */
import p5 from 'p5';
import { store } from '../app/store';
import a from '../assets/a.png';
import { setLoaded } from '../features/sketchLoad';
import image from '../assets/image.json';

export default function sketch(p) {
  let canvas;
  let lines = [];
  let backgroundImage;
  let scale = 850 / window.innerHeight;
  let hidden = false;
  let lineWidth = 10 / scale;
  let line2Width = 35 / scale;
  let lineHeight = 184 / scale;
  let lineSkewWidth = 50 / scale;
  //let imageWidth = 480;
  //let imageHeight = 270;
  let imageWidth = 1920;
  let imageHeight = 1080;
  let imageScale;
  let anim = 0;
  let anim2 = 0;
  let direction = 1;
  let horizontalGlitch = 0;
  let slide = 0;
  let isMouseMoving = false;
  let mouseDir = 1;
  let easing = 0.1;
  let easing2 = 0.05;
  let x = 1;
  let x2 = 1;
  let horizontalDist = false;
  let horizontalDistDuration = 200;
  let currentDistDuration = horizontalDistDuration;
  let scaled = false;
  let glitch = false;
  let linesMove = window.innerWidth;
  let linesMoveDir = -1;
  let lastMouseX = 0;
  let isLoadig = true;
  let colorCoors = [
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
    [0, 0],
  ];

  const setupLines = () => {
    var res = lineHeight - 4;
    var countX = p.ceil((p.width * 3) / (res * 0.315));
    var countY = p.ceil(p.height / res);
    lines = [];

    for (var j = 0; j < countY; j++) {
      for (var i = 0; i < countX; i++) {
        lines.push({
          vector: new p5.Vector(res * (i - countX / 3) * 0.315, res * j),
          widths: [0, 0, 0, 0, 0],
          colors: [
            [0, 0, 0, 255],
            [0, 0, 0, 255],
            [0, 0, 0, 255],
            [0, 0, 0, 255],
            [0, 0, 0, 255],
          ],
          alphas: [0, 0, 0, 0, 0],
          visible: [false, false, false, false, false],
          translate: [false, false, false, false, false],
          horizontalGlitch: false,
          row: j + 1,
        });
      }
    }
  };

  p.setup = () => {
    store.dispatch(setLoaded(true));
    canvas = p.createCanvas(p.windowWidth, p.windowHeight);
    imageScale = imageHeight / p.height;
    //p.noLoop();
    backgroundImage.loadPixels();
    //p.pixelDensity(1.5);

    setupLines();

    p.fill(0);
    p.noStroke();
    p.angleMode(p.DEGREES);
    p.background(230, 230, 230, 0);
    /*
    p.noFill();
    p.stroke(0, 0, 0);
    p.strokeWeight(13);
    */
  };

  p.draw = () => {
    if (!isLoadig) {
      if (-linesMove <= 0) linesMoveDir = 1;
      if (-linesMove >= p.width * 6) linesMoveDir = -1;
      if (!isMouseMoving) linesMove -= 3 * linesMoveDir;
      anim = (p.width / 2 - (isMouseMoving ? p.mouseX : lastMouseX)) * -0.3 - x;
      anim2 =
        (p.width / 2 - (isMouseMoving ? p.mouseX : linesMove)) * -0.05 - x2;
      x += anim * easing;
      x2 += anim2 * easing2;
      scaled = glitch && Math.random() < 0.8 ? !scaled : scaled;
      if (glitch && Math.random() < 0.01) {
        imageScale = (imageHeight / p.height) * scaled ? 0.8 : 2;
      } else {
        imageScale = imageHeight / p.height;
      }
      p.pixelDensity(glitch && Math.random() < 0.002 ? 0.08 : 2);
      p.background(230, 230, 230, 0);
      //p.background(texture);
      //p.smooth();
      for (var i = lines.length - 1; i >= 0; i--) {
        //console.log(!!locs[i + 1], locs[i + 1].y, locs[i].y);
        if (!!lines[i + 1] && lines[i + 1].vector.y !== lines[i].vector.y) {
          direction *= -1;
          horizontalDist =
            glitch && Math.random() < 0.001 && Math.random() < 0.5
              ? !horizontalDist
              : horizontalDist;
          if (horizontalDist && currentDistDuration) {
            currentDistDuration -= 1;
          } else {
            currentDistDuration =
              glitch && Math.random() < 0.85
                ? horizontalDistDuration * 2
                : horizontalDistDuration;
            horizontalDist = false;
          }
          lines[i].horizontalGlitch =
            glitch && Math.random() < 0.08
              ? !lines[i].horizontalGlitch
              : lines[i].horizontalGlitch;
          horizontalGlitch =
            glitch &&
              ((lines[i].horizontalGlitch &&
                horizontalDist &&
                currentDistDuration &&
                !isMouseMoving) ||
                Math.random() < 0.001)
              ? glitch && Math.random() < 0.85
                ? lineSkewWidth * 1.5
                : lineSkewWidth * 2
              : 0;
          p.translate(10 / scale, 0);
        }
        p.push();
        p.translate(
          ...[
            lines[i].vector.x + x2 * (direction > 0 ? 1 + i * 0.005 : 1),
            lines[i].vector.y,
          ]
        );
        //console.log(locs[i].y, direction);
        //p.rotate(0.261799);
        //p.line(0, 0, 0, lineSize);
        colorCoors[0] = [
          parseInt(
            (lines[i].vector.x +
              x2 * (direction > 0 ? 1 + i * 0.005 : 1) -
              (p.width - imageWidth / imageScale) / 2) *
            imageScale +
            ((lineSkewWidth / 4) * 4 - lineSkewWidth / 8) +
            x
          ),
          lines[i].row * 4 - 3,
        ];
        lines[i].colors[0] = lineColor(
          colorCoors[0][0] < imageWidth &&
            colorCoors[0][0] > 0 &&
            colorCoors[0][1] < 20 &&
            colorCoors[0][1] > 0
            ? image.bitmap[colorCoors[0][0]][colorCoors[0][1]]
            : [0, 0, 0, 0]
        );
        if (hidden) {
          if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.015;
          if (lines[i].visible[0]) {
            lines[i].widths[0] -= lines[i].widths[0] > 0 ? 5 : 0;
          }
          if (lines[i].visible[0]) {
            lines[i].alphas[0] -= lines[i].alphas[0] > 0 ? 40 : 0;
          }
        } else {
          if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.015;
          if (lines[i].visible[0]) {
            lines[i].widths[0] +=
              !!lines[i].colors[0][3] && lines[i].widths[0] < line2Width
                ? 5
                : !lines[i].colors[0][3] && lines[i].widths[0] > 0
                  ? -5
                  : 0;
          }
          if (lines[i].visible[0]) {
            lines[i].alphas[0] +=
              !!lines[i].colors[0][3] && lines[i].alphas[0] < 255
                ? 40
                : !lines[i].colors[0][3] && lines[i].alphas[0] > 0
                  ? -40
                  : 0;
          }
        }
        p.rotate(0);
        p.fill(
          lines[i].widths[0] > 0 ? [0, 0, 0, lines[i].alphas[0]] : [0, 0, 0, 0]
        );
        lines[i].translate[0] =
          glitch && Math.random() < 0.0005 ? lineSkewWidth / 2 : 0;
        p.quad(
          lineSkewWidth + lines[i].translate[0] + horizontalGlitch,
          0,
          lineSkewWidth +
          lines[i].widths[0] +
          lines[i].translate[0] +
          horizontalGlitch,
          0,
          (lineSkewWidth / 4) * 3 +
          lines[i].widths[0] +
          lines[i].translate[0] +
          horizontalGlitch,
          lineHeight / 4,
          (lineSkewWidth / 4) * 3 + lines[i].translate[0] + horizontalGlitch,
          lineHeight / 4
        );
        colorCoors[1] = [
          parseInt(
            (lines[i].vector.x +
              x2 * (direction > 0 ? 1 + i * 0.005 : 1) -
              (p.width - imageWidth / imageScale) / 2) *
            imageScale +
            ((lineSkewWidth / 4) * 3 - lineSkewWidth / 8) +
            x
          ),
          lines[i].row * 4 - 2,
        ];
        lines[i].colors[1] = lineColor(
          colorCoors[1][0] < imageWidth &&
            colorCoors[1][0] > 0 &&
            colorCoors[1][1] < 20 &&
            colorCoors[1][1] > 0
            ? image.bitmap[colorCoors[1][0]][colorCoors[1][1]]
            : [0, 0, 0, 0]
        );
        if (hidden) {
          if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.01;
          if (lines[i].visible[0]) {
            lines[i].widths[1] -= lines[i].widths[1] > 0 ? 5 : 0;
          }
          if (lines[i].visible[0]) {
            lines[i].alphas[1] -= lines[i].alphas[1] > 0 ? 40 : 0;
          }
        } else {
          if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.01;
          if (lines[i].visible[0]) {
            lines[i].widths[1] +=
              !!lines[i].colors[1][3] && lines[i].widths[1] < line2Width
                ? 5
                : !lines[i].colors[1][3] && lines[i].widths[1] > 0
                  ? -5
                  : 0;
          }
          if (lines[i].visible[0]) {
            lines[i].alphas[1] +=
              !!lines[i].colors[1][3] && lines[i].alphas[1] < 255
                ? 40
                : !lines[i].colors[1][3] && lines[i].alphas[1] > 0
                  ? -40
                  : 0;
          }
        }
        p.fill(
          lines[i].widths[1] > 0 ? [0, 0, 0, lines[i].alphas[1]] : [0, 0, 0, 0]
        );
        lines[i].translate[1] =
          glitch && Math.random() < 0.0005 ? lineSkewWidth / 2 : 0;
        p.quad(
          lineSkewWidth -
          (lineSkewWidth / 4) * 1 +
          lines[i].translate[1] +
          horizontalGlitch,
          lineHeight / 4,
          lineSkewWidth -
          (lineSkewWidth / 4) * 1 +
          lines[i].widths[1] +
          lines[i].translate[1] +
          horizontalGlitch,
          lineHeight / 4,
          (lineSkewWidth / 4) * 2 +
          lines[i].widths[1] +
          lines[i].translate[1] +
          horizontalGlitch,
          (lineHeight / 4) * 2,
          (lineSkewWidth / 4) * 2 + lines[i].translate[1] + horizontalGlitch,
          (lineHeight / 4) * 2
        );
        colorCoors[2] = [
          parseInt(
            (lines[i].vector.x +
              x2 * (direction > 0 ? 1 + i * 0.005 : 1) -
              (p.width - imageWidth / imageScale) / 2) *
            imageScale +
            ((lineSkewWidth / 4) * 2 - lineSkewWidth / 8) +
            x
          ),
          lines[i].row * 4 - 1,
        ];
        lines[i].colors[2] = lineColor(
          colorCoors[2][0] < imageWidth &&
            colorCoors[2][0] > 0 &&
            colorCoors[2][1] < 20 &&
            colorCoors[2][1] > 0
            ? image.bitmap[colorCoors[2][0]][colorCoors[2][1]]
            : [0, 0, 0, 0]
        );
        if (hidden) {
          if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.015;
          if (lines[i].visible[0]) {
            lines[i].widths[2] -= lines[i].widths[2] > 0 ? 5 : 0;
          }
          if (lines[i].visible[0]) {
            lines[i].alphas[2] -= lines[i].alphas[2] > 0 ? 40 : 0;
          }
        } else {
          if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.015;
          if (lines[i].visible[0]) {
            lines[i].widths[2] +=
              !!lines[i].colors[2][3] && lines[i].widths[2] < line2Width
                ? 5
                : !lines[i].colors[2][3] && lines[i].widths[2] > 0
                  ? -5
                  : 0;
          }
          if (lines[i].visible[0]) {
            lines[i].alphas[2] +=
              !!lines[i].colors[2][3] && lines[i].alphas[2] < 255
                ? 40
                : !lines[i].colors[2][3] && lines[i].alphas[2] > 0
                  ? -40
                  : 0;
          }
        }
        p.fill(
          lines[i].widths[2] > 0 ? [0, 0, 0, lines[i].alphas[2]] : [0, 0, 0, 0]
        );
        lines[i].translate[2] =
          glitch && Math.random() < 0.0005 ? lineSkewWidth / 2 : 0;
        p.quad(
          lineSkewWidth -
          (lineSkewWidth / 4) * 2 +
          lines[i].translate[2] +
          horizontalGlitch,
          (lineHeight / 4) * 2,
          lineSkewWidth -
          (lineSkewWidth / 4) * 2 +
          lines[i].widths[2] +
          lines[i].translate[2] +
          horizontalGlitch,
          (lineHeight / 4) * 2,
          lineSkewWidth / 4 +
          lines[i].widths[2] +
          lines[i].translate[2] +
          horizontalGlitch,
          (lineHeight / 4) * 3,
          lineSkewWidth / 4 + lines[i].translate[2] + horizontalGlitch,
          (lineHeight / 4) * 3
        );
        colorCoors[3] = [
          parseInt(
            (lines[i].vector.x +
              x2 * (direction > 0 ? 1 + i * 0.005 : 1) -
              (p.width - imageWidth / imageScale) / 2) *
            imageScale +
            ((lineSkewWidth / 4) * 1 - lineSkewWidth / 8) +
            x
          ),
          lines[i].row * 4,
        ];
        lines[i].colors[3] = lineColor(
          colorCoors[3][0] < imageWidth &&
            colorCoors[3][0] > 0 &&
            colorCoors[3][1] < 20 &&
            colorCoors[3][1] > 0
            ? image.bitmap[colorCoors[3][0]][colorCoors[3][1]]
            : [0, 0, 0, 0]
        );
        if (hidden) {
          if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.01;
          if (lines[i].visible[0]) {
            lines[i].widths[3] -= lines[i].widths[3] > 0 ? 5 : 0;
          }
          if (lines[i].visible[0]) {
            lines[i].alphas[3] -= lines[i].alphas[3] > 0 ? 40 : 0;
          }
        } else {
          if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.01;
          if (lines[i].visible[0]) {
            lines[i].widths[3] +=
              !!lines[i].colors[3][3] && lines[i].widths[3] < line2Width
                ? 5
                : !lines[i].colors[3][3] && lines[i].widths[3] > 0
                  ? -5
                  : 0;
          }
          if (lines[i].visible[0]) {
            lines[i].alphas[3] +=
              !!lines[i].colors[3][3] && lines[i].alphas[3] < 255
                ? 40
                : !lines[i].colors[3][3] && lines[i].alphas[3] > 0
                  ? -40
                  : 0;
          }
        }
        p.fill(
          lines[i].widths[3] > 0 ? [0, 0, 0, lines[i].alphas[3]] : [0, 0, 0, 0]
        );
        lines[i].translate[3] =
          glitch && Math.random() < 0.0005 ? lineSkewWidth / 2 : 0;
        p.quad(
          lineSkewWidth -
          (lineSkewWidth / 4) * 3 +
          lines[i].translate[3] +
          horizontalGlitch,
          (lineHeight / 4) * 3,
          lineSkewWidth -
          (lineSkewWidth / 4) * 3 +
          lines[i].widths[3] +
          lines[i].translate[3] +
          horizontalGlitch,
          (lineHeight / 4) * 3,
          lines[i].widths[3] + lines[i].translate[3] + horizontalGlitch,
          lineHeight,
          0 + lines[i].translate[3] + horizontalGlitch,
          lineHeight
        );
        colorCoors[4] = [
          parseInt(
            (lines[i].vector.x +
              x2 * (direction > 0 ? 1 + i * 0.005 : 1) -
              (p.width - imageWidth / imageScale) / 2) *
            imageScale +
            ((lineSkewWidth / 4) * 4 - lineSkewWidth / 8) +
            x
          ),
          lines[i].row * 4 - 3,
        ];
        lines[i].colors[4] = lineColor(
          colorCoors[4][0] < imageWidth &&
            colorCoors[4][0] > 0 &&
            colorCoors[4][1] < 20 &&
            colorCoors[4][1] > 0
            ? image.bitmap[colorCoors[4][0]][colorCoors[4][1]]
            : [0, 0, 0, 0]
        );
        if (hidden) {
          if (!lines[i].visible[4]) lines[i].visible[4] = Math.random() < 0.05;
          if (lines[i].visible[4])
            lines[i].widths[4] -= lines[i].widths[4] > 0 ? 3 : 0;
          if (lines[i].visible[4])
            lines[i].alphas[4] -= lines[i].alphas[4] > 0 ? 30 : 0;
        } else {
          if (!lines[i].visible[4]) lines[i].visible[4] = Math.random() < 0.05;
          if (lines[i].visible[4])
            lines[i].widths[4] += lines[i].widths[4] < lineWidth ? 3 : 0;
          if (lines[i].visible[4])
            lines[i].alphas[4] += lines[i].alphas[4] < 255 ? 30 : 0;
        }
        p.fill(
          lines[i].widths[4] > 0 ? [0, 0, 0, lines[i].alphas[4]] : [0, 0, 0, 0]
        );
        lines[i].translate[4] =
          glitch && Math.random() < 0.0002 ? lineSkewWidth / 2 : 0;
        p.quad(
          lineSkewWidth + horizontalGlitch + lines[i].translate[4],
          0,
          lineSkewWidth +
          lines[i].widths[4] +
          horizontalGlitch +
          lines[i].translate[4],
          0,
          lines[i].widths[4] + horizontalGlitch + lines[i].translate[4],
          lineHeight,
          0 + horizontalGlitch + lines[i].translate[4],
          lineHeight
        );
        p.pop();
      }
      //p.quad(50, 0, 63, 0, 13, 185, 0, 185);
    }
  };

  p.preload = () => {
    backgroundImage = p.loadImage(a);
  };

  const lineColor = (color) => {
    color[3] = color[3] ? 255 : 0;
    return color;
  };

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
    scale = 850 / p.windowHeight;
    lineWidth = 10 / scale;
    line2Width = 35 / scale;
    lineHeight = 184 / scale;
    lineSkewWidth = 50 / scale;
    setupLines();
  };

  p.myCustomRedrawAccordingToNewPropsHandler = (newProps) => {
    if (canvas) {
      //Make sure the canvas has been created
      isLoadig = newProps.isLoadig;
      if (hidden !== newProps.hidden) {
        lines.forEach((e, i) => {
          lines[i].visible = lines[i].visible.map((e) => 0);
        });
      }
      hidden = newProps.hidden;
      if (isMouseMoving !== newProps.isMouseMoving) {
        isMouseMoving = newProps.isMouseMoving;
        if (!isMouseMoving) {
          linesMove = p.mouseX;
          lastMouseX = p.mouseX;
        }
      }
    }
  };
}
