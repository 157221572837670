import { createSlice } from '@reduxjs/toolkit';

export const sketchLoadSlice = createSlice({
  name: 'sketchLoad',
  initialState: {
    loaded: false,
    hidden: false,
  },
  reducers: {
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setHidden: (state, action) => {
      state.hidden = action.payload;
    },
  },
});

export const { setLoaded, setHidden } = sketchLoadSlice.actions;

export default sketchLoadSlice.reducer;
