import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import P5Wrapper from 'react-p5-wrapper';
import sketch from './sketches/sketch';
import sketch2 from './sketches/sketch2';
import sketch3 from './sketches/sketch3';
import sketch4 from './sketches/sketch4';
import sketch5 from './sketches/sketch5';
import sketch6 from './sketches/sketch6';
import texture from './assets/textures/texture.webp';
//import AddIcon from '@material-ui/icons/Add';

import './App.css';
import Home from './components/Home';
import { useSelector } from 'react-redux';

function App() {
  const sketches = [sketch6, sketch5, sketch, sketch4, sketch3, sketch2];
  const [isMouseMoving, setMouseMoving] = useState(false);
  const [currentTexture, setCurrentTexture] = useState(0);
  const [isLoadig, setLoading] = useState(true);
  const [isTextureLoadig, setTextureLoading] = useState(true);
  const [textureCounter, setTextureCounter] = useState(0);
  const [isTextureMoving, setTextureMoving] = useState(false);
  const [textureSpeed, setTextureSpeed] = useState(50);
  // eslint-disable-next-line no-unused-vars
  const [isSketchVisible, setSketchVisible] = useState(true);
  const { loaded, hidden } = useSelector((state) => state.sketchLoad);

  var c = new Image(texture);
  c.onload = function () {
    //render the form
    console.log('loaded');
  };

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoading(false);
        setTimeout(() => {
          setSketchVisible(false);
          setTimeout(() => {
            setSketchVisible(true);
          }, 3000);
        }, 3000);
      }, 1500);
    }
  }, [loaded]);

  useEffect(() => {
    /*
      1. texture speed
      2. texture duration
      3 texture gap
    */
    setTimeout(
      () => {
        if (!isTextureMoving) {
          if (Math.random() < 0.5) {
            setTextureSpeed(Math.floor(Math.random() * (100 - 50) + 50));
          } else {
            setTextureSpeed(Math.floor(Math.random() * (300 - 50) + 50));
          }
        }
        setTextureMoving(!isTextureMoving);
      },
      isTextureMoving
        ? Math.floor(Math.random() * (1500 - 500) + 500)
        : Math.random() < 0.5
          ? Math.floor(Math.random() * (3000 - 1000) + 2000)
          : Math.floor(Math.random() * (10000 - 5000) + 2000)
    );
  }, [isTextureMoving]);

  useEffect(() => {
    if (isTextureMoving || isTextureLoadig) {
      setTimeout(() => {
        setCurrentTexture(currentTexture < 5 ? currentTexture + 1 : 0);
        setTextureCounter(
          textureCounter !== textureSpeed && !isTextureLoadig && isTextureMoving
            ? textureSpeed
            : textureCounter + 20
        );
      }, textureCounter);
    }
    if (!isLoadig && isTextureLoadig) {
      setTimeout(() => {
        setTextureLoading(false);
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTextureMoving, currentTexture, isLoadig, isTextureLoadig]);

  const handleClick = () => { };

  const stopMoving = debounce(() => {
    if (isMouseMoving) setMouseMoving(false);
  }, 150);

  const handleMouseMove = (e) => {
    /*
    document.querySelector(
      ".sketch"
    ).style.transform = `perspective(100px) rotateX(${
      -(e.clientY / (window.innerHeight / 2) - 1) * 0.8
    }deg) rotateY(${
      (e.clientX / (window.innerWidth / 2) - 1) * 0.8
    }deg) translate(${(window.innerWidth / 2 - e.clientX) * 0.005}px, ${
      (window.innerHeight / 2 - e.clientY) * 0.01
    }px)`;
    */
    if (!isMouseMoving) setMouseMoving(true);
    stopMoving();
  };

  return (
    <div
      onClick={handleClick}
      onTouchMove={handleMouseMove}
      onMouseMove={handleMouseMove}
      className="App"
    >
      <div className="sketch" style={{ background: !loaded ? '#E6E6E6' : '' }}>
        <div
          className="canvas"
          style={{
            maskImage: `url(${texture})`,
            WebkitMaskImage: `url(${texture})`,
            maskPosition: `0 -${currentTexture * 100}vh`,
            WebkitMaskPosition: `0 -${currentTexture * 100}vh`,
          }}
        >
          <P5Wrapper
            sketch={sketches[1]}
            isMouseMoving={isMouseMoving}
            isLoadig={isLoadig}
            hidden={hidden}
          ></P5Wrapper>
        </div>
      </div>
      {!isLoadig && <Home mask={texture} currentTexture={currentTexture} />}
    </div>
  );
}

export default App;
