/* eslint-disable no-unused-vars */
import p5 from "p5";
import { store } from "../app/store";
import a from "../assets/a.png";
import { setLoaded } from "../features/sketchLoad";

export default function sketch(p) {
  let canvas;
  let lines = [];
  let backgroundImage;
  let scale = 850 / window.innerHeight;
  let lineWidth = 10 / scale;
  let line2Width = 35 / scale;
  let lineHeight = 184 / scale;
  let lineSkewWidth = 50 / scale;
  let imageWidth = 1920;
  let imageHeight = 1080;
  let imageScale;
  let anim = 0;
  let anim2 = 0;
  let direction = 1;
  let isMouseMoving = false;
  let easing = 0.1;
  let easing2 = 0.05;
  let x = 1;
  let x2 = 1;
  let linesMove = window.innerWidth;
  let linesMoveDir = -1;
  let lastMouseX = 0;
  let isLoadig = true;
  let colors;
  let pixels = [];

  const setupLines = () => {
    var res = lineHeight - 4;
    var countX = p.ceil((p.width * 3) / (res * 0.315));
    var countY = p.ceil(p.height / res);
    lines = [];

    for (var j = 0; j < countY; j++) {
      for (var i = 0; i < countX; i++) {
        lines.push({
          vector: new p5.Vector(res * (i - countX / 3) * 0.315, res * j),
          widths: [0, 0, 0, 0, 0],
          colors: [
            [0, 0, 0, 255],
            [0, 0, 0, 255],
            [0, 0, 0, 255],
            [0, 0, 0, 255],
            [0, 0, 0, 255],
          ],
          alphas: [0, 0, 0, 0, 0],
          visible: [false, false, false, false, false],
          horizontalGlitch: false,
        });
      }
    }
  };

  p.setup = () => {
    store.dispatch(setLoaded(true));
    canvas = p.createCanvas(p.windowWidth, p.windowHeight);
    imageScale = imageHeight / p.height;
    //p.noLoop();
    backgroundImage.loadPixels();
    p.pixelDensity(2);

    setupLines();

    p.fill(0);
    p.noStroke();
    p.angleMode(p.DEGREES);
    p.background(230, 230, 230);

    /*
    let pixelsAux = [];
    for (let i = 0; i < 1920; i++) {
      pixelsAux = [];
      for (let j = 0; j < 20; j++) {
        pixelsAux.push(backgroundImage.get(i, j * (1080 / 20) + 1080 / 40));
        console.log(j, j * (1080 / 20) + 1080 / 40);
      }
      pixels.push(pixelsAux);
    }
    let bitmap = { bitmap: pixels };
    console.log(bitmap);

    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(bitmap));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", "exportName" + ".json");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
     */
  };

  p.draw = () => {
    if (!isLoadig) {
      if (-linesMove <= 0) linesMoveDir = 1;
      if (-linesMove >= p.width * 6) linesMoveDir = -1;
      if (!isMouseMoving) linesMove -= 3 * linesMoveDir;
      anim = (p.width / 2 - (isMouseMoving ? p.mouseX : lastMouseX)) * -0.3 - x;
      anim2 =
        (p.width / 2 - (isMouseMoving ? p.mouseX : linesMove)) * -0.05 - x2;
      x += anim * easing;
      x2 += anim2 * easing2;
      imageScale = imageHeight / p.height;
      p.background(230, 230, 230);
      //p.background(texture);
      //p.smooth();
      for (var i = lines.length - 1; i >= 0; i--) {
        //console.log(!!locs[i + 1], locs[i + 1].y, locs[i].y);
        if (!!lines[i + 1] && lines[i + 1].vector.y !== lines[i].vector.y) {
          direction *= -1;
          p.translate(10 / scale, 0);
        }
        p.push();
        p.translate(
          ...[
            lines[i].vector.x + x2 * (direction > 0 ? 1 + i * 0.005 : 1),
            lines[i].vector.y,
          ]
        );
        //console.log(locs[i].y, direction);
        //p.rotate(0.261799);
        //p.line(0, 0, 0, lineSize);
        if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.015;
        if (lines[i].visible[0]) {
          lines[i].widths[0] +=
            !!lines[i].colors[0][3] && lines[i].widths[0] < line2Width
              ? 5
              : !lines[i].colors[0][3] && lines[i].widths[0] > 0
                ? -5
                : 0;
        }
        if (lines[i].visible[0]) {
          lines[i].alphas[0] +=
            !!lines[i].colors[0][3] && lines[i].alphas[0] < 255
              ? 40
              : !lines[i].colors[0][3] && lines[i].alphas[0] > 0
                ? -40
                : 0;
        }
        p.rotate(0);
        p.fill(
          lines[i].widths[0] > 0 ? [0, 0, 0, lines[i].alphas[0]] : [0, 0, 0, 0]
        );
        p.quad(
          lineSkewWidth,
          0,
          lineSkewWidth + lines[i].widths[0],
          0,
          (lineSkewWidth / 4) * 3 + lines[i].widths[0],
          lineHeight / 4,
          (lineSkewWidth / 4) * 3,
          lineHeight / 4
        );
        if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.01;
        if (lines[i].visible[0]) {
          lines[i].widths[1] +=
            !!lines[i].colors[1][3] && lines[i].widths[1] < line2Width
              ? 5
              : !lines[i].colors[1][3] && lines[i].widths[1] > 0
                ? -5
                : 0;
        }
        if (lines[i].visible[0]) {
          lines[i].alphas[1] +=
            !!lines[i].colors[1][3] && lines[i].alphas[1] < 255
              ? 40
              : !lines[i].colors[1][3] && lines[i].alphas[1] > 0
                ? -40
                : 0;
        }
        p.fill(
          lines[i].widths[1] > 0 ? [0, 0, 0, lines[i].alphas[1]] : [0, 0, 0, 0]
        );
        p.quad(
          lineSkewWidth - (lineSkewWidth / 4) * 1,
          lineHeight / 4,
          lineSkewWidth - (lineSkewWidth / 4) * 1 + lines[i].widths[1],
          lineHeight / 4,
          (lineSkewWidth / 4) * 2 + lines[i].widths[1],
          (lineHeight / 4) * 2,
          (lineSkewWidth / 4) * 2,
          (lineHeight / 4) * 2
        );
        if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.015;
        if (lines[i].visible[0]) {
          lines[i].widths[2] +=
            !!lines[i].colors[2][3] && lines[i].widths[2] < line2Width
              ? 5
              : !lines[i].colors[2][3] && lines[i].widths[2] > 0
                ? -5
                : 0;
        }
        if (lines[i].visible[0]) {
          lines[i].alphas[2] +=
            !!lines[i].colors[2][3] && lines[i].alphas[2] < 255
              ? 40
              : !lines[i].colors[2][3] && lines[i].alphas[2] > 0
                ? -40
                : 0;
        }
        p.fill(
          lines[i].widths[2] > 0 ? [0, 0, 0, lines[i].alphas[2]] : [0, 0, 0, 0]
        );
        p.quad(
          lineSkewWidth - (lineSkewWidth / 4) * 2,
          (lineHeight / 4) * 2,
          lineSkewWidth - (lineSkewWidth / 4) * 2 + lines[i].widths[2],
          (lineHeight / 4) * 2,
          lineSkewWidth / 4 + lines[i].widths[2],
          (lineHeight / 4) * 3,
          lineSkewWidth / 4,
          (lineHeight / 4) * 3
        );
        if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.01;
        if (lines[i].visible[0]) {
          lines[i].widths[3] +=
            !!lines[i].colors[3][3] && lines[i].widths[3] < line2Width
              ? 5
              : !lines[i].colors[3][3] && lines[i].widths[3] > 0
                ? -5
                : 0;
        }
        if (lines[i].visible[0]) {
          lines[i].alphas[3] +=
            !!lines[i].colors[3][3] && lines[i].alphas[3] < 255
              ? 40
              : !lines[i].colors[3][3] && lines[i].alphas[3] > 0
                ? -40
                : 0;
        }
        p.fill(
          lines[i].widths[3] > 0 ? [0, 0, 0, lines[i].alphas[3]] : [0, 0, 0, 0]
        );
        p.quad(
          lineSkewWidth - (lineSkewWidth / 4) * 3,
          (lineHeight / 4) * 3,
          lineSkewWidth - (lineSkewWidth / 4) * 3 + lines[i].widths[3],
          (lineHeight / 4) * 3,
          lines[i].widths[3],
          lineHeight,
          0,
          lineHeight
        );
        if (!lines[i].visible[4]) lines[i].visible[4] = Math.random() < 0.05;
        if (lines[i].visible[4])
          lines[i].widths[4] += lines[i].widths[4] < lineWidth ? 3 : 0;
        if (lines[i].visible[4])
          lines[i].alphas[4] += lines[i].alphas[4] < 255 ? 30 : 0;
        p.fill(
          lines[i].widths[4] > 0 ? [0, 0, 0, lines[i].alphas[4]] : [0, 0, 0, 0]
        );
        p.quad(
          lineSkewWidth,
          0,
          lineSkewWidth + lines[i].widths[4],
          0,
          lines[i].widths[4],
          lineHeight,
          0,
          lineHeight
        );
        p.pop();
      }
      //p.quad(50, 0, 63, 0, 13, 185, 0, 185);
    }
  };

  p.preload = () => {
    backgroundImage = p.loadImage(a);
  };

  const lineColor = (color) => {
    color[3] = color[3] ? 255 : 0;
    return color;
  };

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
    scale = 850 / p.windowHeight;
    lineWidth = 10 / scale;
    line2Width = 35 / scale;
    lineHeight = 184 / scale;
    lineSkewWidth = 50 / scale;
    setupLines();
  };

  p.myCustomRedrawAccordingToNewPropsHandler = (newProps) => {
    if (canvas) {
      //Make sure the canvas has been created
      isLoadig = newProps.isLoadig;
      isMouseMoving = newProps.isMouseMoving;
      if (!isMouseMoving) {
        linesMove = p.mouseX;
        lastMouseX = p.mouseX;
      }
    }
  };
}
