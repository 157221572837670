import React, { useEffect, useRef, useState } from 'react';
import alucinaLogo from '../assets/alucina.svg';
import logos from '../assets/logos.webp';
import Contact from './Contact';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import useWindowSize from '../utils/useWindowSize';
import { store } from '../app/store';
import { setHidden } from '../features/sketchLoad';
import mute from '../assets/icons/volume_off.svg';
import sound from '../assets/icons/volume_on.svg';

export default function Home({ mask, currentTexture }) {
  const windowSize = useWindowSize();
  const [isContactOpen, setContactOpen] = useState(false);
  const [isVideoOpen, setVideoOpen] = useState(false);
  const [isLoadig, setLoading] = useState(true);
  const [hasDelay, setHasDelay] = useState(true);
  const [audioState, setAudioState] = useState(false);
  const [isAudioPressed, setAudioPressed] = useState(false);
  const reel = useRef();
  const [sections, setSections] = useState([
    {
      width1: windowSize.width > windowSize.height ? '23.2em' : '16.2em',
      width2: '13.2em',
      isHover: false,
    },
    {
      width1: '17.2em',
      width2: 'max(41.2rem, 80vh)',
      isHover: false,
    },
    {
      width1: '15.2em',
      width2: '18.2em',
      isHover: false,
    },
  ]);

  useEffect(() => {
    sections[0].width1 =
      windowSize.width > windowSize.height ? '23.2em' : '16.2em';
    sections[1].width1 =
      windowSize.width > windowSize.height ? '17.2em' : '16.4em';
    sections[1].width2 =
      windowSize.width > windowSize.height ? 'min(31.2em, 80vw)' : '17.2em';
    sections[2].width1 =
      windowSize.width > windowSize.height ? '15.2em' : '14.4em';
    setSections(sections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 700);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setHasDelay(false);
    }, 1500);
  }, []);

  const handleAudio = () => {
    reel.current.muted = !reel.current.muted;
    setAudioPressed(true);
    setTimeout(() => {
      setAudioPressed(false);
      setAudioState(!audioState);
    }, 100);
  };

  const handleBtn = (e) => {
    let current = e.currentTarget;
    current.classList.add('active');
    setTimeout(() => {
      current.classList.remove('active');
    }, 100);
  };

  const onHover = (i) => {
    if (!hasDelay) {
      sections[i].isHover = true;
      setSections([...sections]);
    }
  };

  const onHoverOut = (i) => {
    if (!hasDelay) {
      sections[i].isHover = false;
      setSections([...sections]);
    }
  };

  const handleVideo = (isOpen) => {
    if (isVideoOpen !== isOpen) {
      setTimeout(
        () => {
          store.dispatch(setHidden(isOpen));
        },
        isVideoOpen ? 300 : 0
      );
      if (isVideoOpen) {
        reel.current.pause();
        setTimeout(() => {
          reel.current.currentTime = 0;
        }, 500);
      } else {
        reel.current.play();
        reel.current.muted = true;
      }
      setTimeout(
        () => {
          setVideoOpen(isOpen);
          setHasDelay(true);
          setTimeout(() => {
            setHasDelay(false);
          }, 2000);
        },
        isVideoOpen ? 0 : 600
      );
      if (audioState) {
        setTimeout(() => {
          handleAudio();
        }, 1000);
      }
    }
  };

  const handleContact = (isOpen) => {
    if (isOpen && !isContactOpen) {
      setContactOpen(isOpen);
      setHasDelay(true);
      setTimeout(() => {
        setHasDelay(false);
      }, 2000);
    }
  };

  const closeContact = () => {
    if (isContactOpen) setContactOpen(false);
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdO6CgbAAAAAGmJgkqxbGYRn8C8LYYRpChtaGA8">
      <div className={`home ${isVideoOpen ? 'video-open' : ''}`}>
        <video
          ref={reel}
          className="reel"
          src={'https://alucinastudio.com/_Reel_Alucina_2022.mp4'}
        >
          Tu navegador no admite el elemento <code>video</code>.
        </video>
        <div className={`logo ${isLoadig ? ' loading' : ''}`}>
          <div>
            <img alt="ALUCINA STUDIO" src={alucinaLogo} />
          </div>
        </div>
        <div className="content">
          <div
            onMouseOver={() => onHover(0)}
            onMouseOut={() => onHoverOut(0)}
            className={
              (sections[0].isHover ? 'hover' : '') +
              (isLoadig ? ' loading' : '') +
              (isVideoOpen || isContactOpen ? 'hidden' : '')
            }
            style={{
              width: sections[0].width1,
              height: windowSize.width > windowSize.height ? '4.2em' : '5.4em',
            }}
          >
            <div
              style={{
                width: `${sections[0].isHover ? 0 : sections[0].width1}`,
                left: sections[0].isHover ? '-0.5em' : '',
              }}
            >
              {windowSize.width > windowSize.height ? (
                <div>
                  {`> ALUCINA CREATES STUNNING PRODUCTS`}
                  <br />
                  {`IN THE INTER`}
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  {`SECTION`}
                  <br />
                  {`OF ART AND TECHNOLOGY`}
                </div>
              ) : (
                <div>
                  {`> ALUCINA CREATES`}
                  <br />
                  {`STUNNING PRODUCTS IN THE`}
                  <br />
                  {`INTER`}
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  <span>_</span>
                  {`SECTION OF`}
                  <br />
                  {`ART AND TECHNOLOGY`}
                </div>
              )}
            </div>
            <div
              style={{
                width: `${!sections[0].isHover ? 0 : sections[0].width2}`,
                left: !sections[0].isHover
                  ? `calc(${sections[0].width1} - 0.5em)`
                  : '',
              }}
            >
              {windowSize.width > windowSize.height ? (
                <div
                  style={{
                    transform: `translate(0em)`,
                  }}
                >
                  {`> METAVERSES_VR_AR`}
                  <br />
                  {`VIDEOGAMES_APPS_WEB`}
                  <br />
                  &nbsp;
                </div>
              ) : (
                <div
                  style={{
                    transform: `translate(-0.5em)`,
                  }}
                >
                  {`> METAVERSES_VR_AR`}
                  <br />
                  {`VIDEOGAMES_APPS_WEB`}
                  <br />
                  &nbsp;
                  <br />
                  &nbsp;
                </div>
              )}
            </div>
          </div>
          <div
            onMouseOver={() => onHover(1)}
            onMouseOut={() => onHoverOut(1)}
            className={
              (sections[1].isHover ? 'hover' : '') +
              (isLoadig ? 'loading' : '') +
              (isVideoOpen || isContactOpen ? 'hidden' : '')
            }
            style={{
              width: sections[1].width1,
              transitionDelay: hasDelay ? '150ms' : 0,
              height: '3em',
            }}
          >
            <div
              style={{
                width: `${sections[1].isHover ? 0 : sections[1].width1}`,
                left: sections[1].isHover ? '-0.5em' : '',
                transitionDelay: hasDelay ? '150ms' : '',
              }}
            >
              <div>
                {`> FOR IMPORTANT`}
                <span>_</span>
                <span>_</span>
                <span>_</span>
                <span>_</span>
                {`BRANDS`}
                <br />
                {`AROUND THE WORLD`}
              </div>
            </div>
            <div
              className="logos"
              style={{
                width: `${!sections[1].isHover ? 0 : sections[1].width2}`,
                height: '2.4em',
                left: !sections[1].isHover
                  ? `calc(${sections[1].width1} - 0.5em)`
                  : '',
              }}
            >
              <img
                alt=""
                src={logos}
                style={{
                  width: `calc(${sections[1].width2} - 1.2em)`,
                }}
              />
            </div>
          </div>

          <div
            onMouseOver={() => onHover(2)}
            onMouseOut={() => onHoverOut(2)}
            className={
              (sections[2].isHover ? 'hover' : '') +
              (isLoadig ? 'loading' : '') +
              (isVideoOpen || isContactOpen ? 'hidden' : '')
            }
            style={{
              width: sections[2].width1,
              transitionDelay: hasDelay ? '300ms' : '',
              height: '3em',
            }}
          >
            <div
              style={{
                width: `${sections[2].isHover ? 0 : sections[2].width1}`,
                left: sections[2].isHover ? '-0.5em' : '',
                transitionDelay: hasDelay ? '300ms' : '',
              }}
            >
              <div>
                {`> FROM MEXICO`}
                <span>_</span>
                <span>_</span>
                <span>_</span>
                <span>_</span>
                <span>_</span>
                {`CITY`}
                <br />
                {`SINCE 2008`}
              </div>
            </div>

            <div
              style={{
                width: `${!sections[2].isHover ? 0 : sections[2].width2}`,
                left: !sections[2].isHover
                  ? `calc(${sections[2].width1} - 0.5em)`
                  : '',
              }}
            >
              <div
                style={{
                  transform: `translate(0em)`,
                }}
              >
                {`>`}&nbsp;{`CREATING`}&nbsp;{'A'}
                <span>_</span>
                <span>_</span>
                <span>_</span>
                <span>_</span>
                <span>_</span>
                <span>_</span>
                <span>_</span>
                {`BEAUTUFUL`}
                <br />
                {`FUTURE`}
              </div>
            </div>
          </div>
          <div className="contact-btn-container">
            <div className="btn-wrap">
              <div
                style={{
                  transitionDelay: hasDelay ? '600ms' : '',
                }}
                className={`contact-btn back ${isLoadig || isVideoOpen || isContactOpen ? 'loading' : ''
                  } ${isContactOpen ? 'open' : ''}`}
                onClick={() => handleContact(true)}
              >
                <div>
                  <div>
                    {'CONTACT'}&nbsp;{'>'}
                  </div>
                </div>
              </div>
              <div
                className={`btn-hover-wrap-container  ${isLoadig || isVideoOpen || isContactOpen ? 'loading' : ''
                  }`}
                style={{
                  transitionDelay: hasDelay ? '600ms' : '',
                }}
              >
                <div className="btn-hover-wrap">
                  <div
                    style={{
                      transitionDelay: hasDelay ? '600ms' : '',
                    }}
                    className={`contact-btn front ${isLoadig || isVideoOpen || isContactOpen ? 'loading' : ''
                      } ${isContactOpen ? 'open' : ''}`}
                    onClick={() => handleContact(true)}
                  >
                    <div
                      style={{
                        transitionDelay: hasDelay ? '600ms' : '',
                      }}
                    >
                      <div>
                        {'CONTACT'}&nbsp;{'>'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="btn-wrap projects"
              style={{
                pointerEvents: isVideoOpen || hasDelay ? 'none' : 'all',
              }}
              onClick={handleBtn}
            >
              <div
                style={{
                  transitionDelay: hasDelay ? '500ms' : '',
                }}
                className={`contact-btn back projects ${isLoadig || isVideoOpen || isContactOpen ? 'loading' : ''
                  } `}
                onClick={() => handleVideo(true)}
              >
                <div
                  style={{
                    transitionDelay: hasDelay ? '500ms' : '',
                  }}
                >
                  <div>
                    {'WORK'}&nbsp;{'>'}
                  </div>
                </div>
              </div>

              <div
                className={`btn-hover-wrap-container  ${isLoadig || isVideoOpen || isContactOpen ? 'loading' : ''
                  }`}
                style={{
                  transitionDelay: hasDelay ? '500ms' : '',
                }}
              >
                <div className="btn-hover-wrap">
                  <div
                    style={{
                      transitionDelay: hasDelay ? '500ms' : '',
                    }}
                    className={`contact-btn front projects ${isLoadig || isVideoOpen || isContactOpen ? 'loading' : ''
                      } `}
                    onClick={() => handleVideo(true)}
                  >
                    <div
                      style={{
                        transitionDelay: hasDelay ? '500ms' : '',
                      }}
                    >
                      <div>
                        {'WORK'}&nbsp;{'>'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`contact-btn-container close-video ${!isVideoOpen ? 'hidden' : ''
              }`}
            style={{
              pointerEvents: isVideoOpen ? 'all' : 'none',
            }}
          >
            <div
              className="btn-wrap video"
              style={{
                pointerEvents: isVideoOpen ? 'all' : 'none',
              }}
              onClick={() => handleVideo(false)}
            >
              <div
                style={{
                  transitionDelay: isVideoOpen && hasDelay ? '500ms' : '500ms',
                }}
                className={`contact-btn back video ${!isVideoOpen ? 'loading' : ''
                  } `}
              >
                <div>
                  <div>
                    {'CLOSE'}&nbsp;{'>'}
                  </div>
                </div>
              </div>

              <div
                className={`btn-hover-wrap-container  ${isLoadig || isContactOpen || !isVideoOpen ? 'loading' : ''
                  }`}
                style={{
                  transitionDelay: isVideoOpen && hasDelay ? '500ms' : '500ms',
                }}
              >
                <div
                  className="btn-hover-wrap"
                  style={{
                    transitionDelay: isVideoOpen && hasDelay ? '500ms' : '',
                  }}
                >
                  <div
                    className={`contact-btn front video ${!isVideoOpen ? 'loading' : ''
                      }`}
                    style={{
                      transitionDelay:
                        isVideoOpen && hasDelay ? '500ms' : '500ms',
                    }}
                  >
                    <div
                      style={{
                        transitionDelay:
                          isVideoOpen && hasDelay ? '500ms' : '500ms',
                      }}
                    >
                      <div>
                        {'CLOSE'}&nbsp;{'>'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`contact-btn-container close-contact ${!isVideoOpen ? 'hidden' : ''
              }`}
            style={{
              pointerEvents: isContactOpen ? 'all' : 'none',
            }}
          >
            <div
              className="btn-wrap close-contact"
              style={{
                pointerEvents: isContactOpen ? 'all' : 'none',
              }}
              onClick={closeContact}
            >
              <div
                style={{
                  transitionDelay:
                    isContactOpen && hasDelay ? '1500ms' : '500ms',
                }}
                className={`contact-btn back close-contact ${!isContactOpen ? 'loading' : ''
                  } `}
              >
                <div
                  style={{
                    transitionDelay: isContactOpen ? '' : '500ms',
                  }}
                >
                  <div>
                    {'CLOSE'}&nbsp;{'>'}
                  </div>
                </div>
              </div>

              <div
                className={`btn-hover-wrap-container  ${isLoadig || isVideoOpen || !isContactOpen ? 'loading' : ''
                  }`}
                style={{
                  transitionDelay:
                    isContactOpen && hasDelay ? '1500ms' : '500ms',
                }}
              >
                <div
                  className="btn-hover-wrap"
                  style={{
                    transitionDelay: hasDelay ? '500ms' : '',
                  }}
                >
                  <div
                    className={`contact-btn front video ${!isContactOpen ? 'loading' : ''
                      }`}
                    style={{
                      transitionDelay:
                        isContactOpen && hasDelay ? '1500ms' : '500ms',
                    }}
                  >
                    <div>
                      <div>
                        {'CLOSE'}&nbsp;{'>'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="audio-btn-container">
            <div
              className={`audio-btn ${!isVideoOpen ? 'hidden' : ''} ${isAudioPressed ? 'active' : ''
                }`}
              onClick={handleAudio}
            >
              <div className="icon-wrap">
                <div className="icon">
                  <img alt="CLOSE" src={audioState ? sound : mute} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact
        open={isContactOpen}
        mask={mask}
        currentTexture={currentTexture}
        delay={hasDelay && isContactOpen ? '800ms' : ''}
      />
    </GoogleReCaptchaProvider>
  );
}
