import React, { useState, useCallback, useEffect } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import '../styles/contact.css';
import twitter from '../assets/icons/twitter.svg';
import facebook from '../assets/icons/facebook.svg';
import instagram from '../assets/icons/instagram.svg';
import whatsapp from '../assets/icons/whatsapp.svg';
import useWindowSize from '../utils/useWindowSize';

export default function Contact({ open, mask, currentTexture, delay }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('.');
  const [statusTitle, setStatusTitle] = useState('OOOPS!');
  const { executeRecaptcha } = useGoogleReCaptcha();
  const windowSize = useWindowSize();
  const [lastSize, setLastSize] = useState(windowSize);
  const [resizeViewport, setResizeViewport] = useState(true);

  useEffect(() => {
    if (resizeViewport) {
      setLastSize(windowSize);
      document
        .querySelector('meta[name=viewport]')
        .setAttribute('content', 'width=device-width, initial-scale=1.0');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  const onSubmit = () => {
    handleReCaptchaVerify((token) => {
      setStatusTitle('OOOPS!');
      setStatus('.');
      // eslint-disable-next-line
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (!name.length) return setStatus('Please fill in the name field.');
      if (!email.length) return setStatus('Please fill in the email field.');
      if (!message.length)
        return setStatus('Please fill in the message field.');
      if (!emailPattern.test(email))
        return setStatus('Please fill a valid email address!');

      setStatusTitle('SENDING...');
      setStatus('Please wait.');
      fetch('https://xfg47zlsl9.execute-api.us-east-2.amazonaws.com/alucinastudio/contact', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, name, message }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === 200) {
            setStatusTitle('SUCCESS');
            setStatus(
              'We have receive your message, we’ll get back to you soon.'
            );
          } else {
            setStatusTitle('OOOPS!');
            setStatus(res.message);
          }
        })
        .catch((err) => console.error(err));
    });
  };

  const handleReCaptchaVerify = useCallback(
    async (cb = () => null) => {
      if (!executeRecaptcha) return;
      const token = await executeRecaptcha('contact');
      cb(token);
    },
    [executeRecaptcha]
  );

  const handleViewport = () => {
    if (window.matchMedia('(pointer: coarse)').matches) {
      document
        .querySelector('meta[name=viewport]')
        .setAttribute(
          'content',
          'height=' +
          lastSize.height +
          'px, width=device-width, initial-scale=1.0'
        );
      setResizeViewport(false);
      setTimeout(() => {
        setResizeViewport(true);
      }, 1000);
    }
  };

  return (
    <div
      className={`contact-container ${open ? 'open' : ''}`}
      style={{
        transitionDelay: delay,
      }}
    >
      <div
        className={`contact ${open ? 'open' : ''}`}
        style={{
          maskImage: `url(${mask})`,
          WebkitMaskImage: `url(${mask})`,
          maskPosition: `0 -${(currentTexture + 1) * 100}vh`,
          WebkitMaskPosition: `0 -${(currentTexture + 1 < 6 ? currentTexture + 1 : 0) * 100
            }vh`,
        }}
      >
        <div>
          <div className="content">
            <div>
              <div className="social-media">
                <div>
                  <div
                    className={!open ? 'hidden' : ''}
                    style={{ transitionDelay: '1000ms' }}
                  >
                    <a
                      href="https://www.facebook.com/alucinastudio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="Facebook" src={facebook} />
                    </a>
                  </div>
                </div>
                <div>
                  <div
                    className={!open ? 'hidden' : ''}
                    style={{ transitionDelay: '1070ms' }}
                  >
                    <a
                      href="https://www.instagram.com/alucinastudio/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="Instagram" src={instagram} />
                    </a>
                  </div>
                </div>
                <div>
                  <div
                    className={!open ? 'hidden' : ''}
                    style={{ transitionDelay: '1140ms' }}
                  >
                    <a
                      href="https://twitter.com/AlucinaStudio"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img alt="Twitter" src={twitter} />
                    </a>
                  </div>
                </div>
                <div>
                  <div
                    className={!open ? 'hidden' : ''}
                    style={{ transitionDelay: '1140ms' }}
                  >
                    <a
                      href="https://wa.me/message/7KUY77RJHBIUK1"
                      target={'_blank'}
                    >
                      <img alt="Whatsapp" src={whatsapp} />
                    </a>
                  </div>
                </div>
              </div>
              <div className="contact-info">
                <a
                  href="mailto:info@alucinastudio.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {'> info@alucinastudio.com'}
                </a>
                <a href="tel:+525591118827">{'> +5255 9111 8827'}</a>
              </div>
            </div>
            <div>
              <div>
                {'> WANT TO CREATE SOMETHING WITH US?'}
                <br />
                {'DROP US A LINE_'}
              </div>
              <div onClick={handleViewport} onBlur={handleViewport}>
                <div className="field-name">YOUR&nbsp;NAME_</div>{' '}
                <input value={name} onChange={(e) => setName(e.target.value)} />
              </div>
              <div onClick={handleViewport} onBlur={handleViewport}>
                <div className="field-name">YOUR&nbsp;EMAIL_</div>{' '}
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <div className="field-name">YOUR&nbsp;MESSAGE_</div>{' '}
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onClick={handleViewport}
                  onBlur={handleViewport}
                ></textarea>
              </div>
              <div
                className={`error_form ${status.length > 1 ? 'active' : ''}`}
              >
                <p className="error_form__line">{statusTitle}</p>
                <p className="error_form__line">{status}</p>
              </div>

              <div
                className="btn-wrap close-contact"
                style={{
                  pointerEvents: open ? 'all' : 'none',
                }}
                onClick={onSubmit}
              >
                <div
                  style={{
                    transitionDelay: open ? '1300ms' : '500ms',
                  }}
                  className={`contact-btn back close-contact ${!open ? 'loading' : ''
                    } `}
                >
                  <div
                    style={{
                      transitionDelay: open ? '' : '500ms',
                    }}
                  >
                    <div>
                      {'SEND'}&nbsp;{'>'}
                    </div>
                  </div>
                </div>

                <div
                  className={`btn-hover-wrap-container  ${!open ? 'loading' : ''
                    }`}
                  style={{
                    transitionDelay: open ? '1300ms' : '500ms',
                  }}
                >
                  <div
                    className="btn-hover-wrap"
                    style={{
                      transitionDelay: false ? '500ms' : '',
                    }}
                  >
                    <div
                      className={`contact-btn front video ${!open ? 'loading' : ''
                        }`}
                      style={{
                        transitionDelay: open ? '1300ms' : '500ms',
                      }}
                    >
                      <div>
                        <div>
                          {'SEND'}&nbsp;{'>'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="send"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
