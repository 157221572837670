/* eslint-disable no-unused-vars */
import p5 from "p5";
import a from "../assets/a.png";


export default function sketch(p) {
  let canvas;
  var lines = [];
  let backgroundImage;
  let scale = 850 / window.innerHeight;
  let lineWidth = 10 / scale;
  let line2Width = 35 / scale;
  let lineHeight = 184 / scale;
  let lineSkewWidth = 50 / scale;
  let imageWidth = 1920;
  let imageHeight = 1080;
  let imageScale;
  let anim = 0;
  let anim2 = 0;
  let direction = 1;
  let horizontalGlitch = 0;
  let slide = 0;
  let isMouseMoving = false;
  let mouseDir = 1;
  let easing = 0.1;
  let easing2 = 0.05;
  let x = 1;
  let x2 = 1;

  const setupLines = () => {
    var res = lineHeight - 4;
    var countX = p.ceil((p.width * 3) / (res * 0.315));
    var countY = p.ceil(p.height / res);
    lines = [];

    for (var j = 0; j < countY; j++) {
      for (var i = 0; i < countX; i++) {
        lines.push({
          vector: new p5.Vector(res * (i - countX / 3) * 0.315, res * j),
          widths: [0, 0, 0, 0, 0],
          colors: [
            [0, 0, 0, 255],
            [0, 0, 0, 255],
            [0, 0, 0, 255],
            [0, 0, 0, 255],
            [0, 0, 0, 255],
          ],
          alphas: [0, 0, 0, 0, 0],
          visible: [false, false, false, false, false],
          translate: [false, false, false, false, false],
        });
      }
    }
  };

  const easeInOutCubic = (x) => {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
  };

  p.setup = () => {
    canvas = p.createCanvas(p.windowWidth, p.windowHeight);
    imageScale = imageHeight / p.height;
    //p.noLoop();
    backgroundImage.loadPixels();
    //p.pixelDensity(0.1);

    setupLines();

    p.fill(0);
    p.noStroke();
    p.angleMode(p.DEGREES);
    /*
    p.noFill();
    p.stroke(0, 0, 0);
    p.strokeWeight(13);
    */
  };

  p.draw = () => {
    anim = (p.width / 2 - p.mouseX) * -0.4 - x;
    anim2 = (p.width / 2 - p.mouseX) * -0.05 - x2;
    x += anim * easing;
    x2 += anim2 * easing2;
    if (Math.random() < 0.01) {
      imageScale = (imageHeight / p.height) * 2;
    } else {
      imageScale = imageHeight / p.height;
    }
    p.pixelDensity(Math.random() < 0.996 ? 2 : 0.08);
    p.background(230, 230, 230);
    //p.smooth();
    for (var i = lines.length - 1; i >= 0; i--) {
      //console.log(!!locs[i + 1], locs[i + 1].y, locs[i].y);
      if (!!lines[i + 1] && lines[i + 1].vector.y !== lines[i].vector.y) {
        direction *= -1;
        horizontalGlitch = Math.random() < 0.005 ? lineSkewWidth * 1.5 : 0;
        p.translate(10 / scale, 0);
      }
      p.push();
      p.translate(...[lines[i].vector.x + x2 * direction, lines[i].vector.y]);
      //console.log(locs[i].y, direction);
      //p.rotate(0.261799);
      //p.line(0, 0, 0, lineSize);
      lines[i].colors[0] = lineColor(
        backgroundImage.get(
          (lines[i].vector.x +
            x2 * direction -
            (p.width - imageWidth / imageScale) / 2) *
          imageScale +
          ((lineSkewWidth / 4) * 4 - lineSkewWidth / 8) +
          x,
          lines[i].vector.y * imageScale +
          ((lineHeight / 4) * 1 - lineHeight / 8)
        )
      );
      if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.02;
      if (lines[i].visible[0]) {
        lines[i].widths[0] +=
          !!lines[i].colors[0][3] && lines[i].widths[0] < line2Width
            ? 5
            : !lines[i].colors[0][3] && lines[i].widths[0] > 0
              ? -5
              : 0;
      }
      if (lines[i].visible[0]) {
        lines[i].alphas[0] +=
          !!lines[i].colors[0][3] && lines[i].alphas[0] < 255
            ? 40
            : !lines[i].colors[0][3] && lines[i].alphas[0] > 0
              ? -40
              : 0;
      }
      p.rotate(0);
      p.fill(
        lines[i].widths[0] > 0 ? [0, 0, 0, lines[i].alphas[0]] : [0, 0, 0, 0]
      );
      lines[i].translate[0] = Math.random() < 0.0005 ? lineSkewWidth / 2 : 0;
      p.quad(
        lineSkewWidth + lines[i].translate[0] + horizontalGlitch,
        0,
        lineSkewWidth +
        lines[i].widths[0] +
        lines[i].translate[0] +
        horizontalGlitch,
        0,
        (lineSkewWidth / 4) * 3 +
        lines[i].widths[0] +
        lines[i].translate[0] +
        horizontalGlitch,
        lineHeight / 4,
        (lineSkewWidth / 4) * 3 + lines[i].translate[0] + horizontalGlitch,
        lineHeight / 4
      );
      lines[i].colors[1] = lineColor(
        backgroundImage.get(
          (lines[i].vector.x +
            x2 * direction -
            (p.width - imageWidth / imageScale) / 2) *
          imageScale +
          ((lineSkewWidth / 4) * 3 - lineSkewWidth / 8) +
          x,
          lines[i].vector.y * imageScale +
          ((lineHeight / 4) * 2 - lineHeight / 8)
        )
      );
      if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.02;
      if (lines[i].visible[0]) {
        lines[i].widths[1] +=
          !!lines[i].colors[1][3] && lines[i].widths[1] < line2Width
            ? 5
            : !lines[i].colors[1][3] && lines[i].widths[1] > 0
              ? -5
              : 0;
      }
      if (lines[i].visible[0]) {
        lines[i].alphas[1] +=
          !!lines[i].colors[1][3] && lines[i].alphas[1] < 255
            ? 40
            : !lines[i].colors[1][3] && lines[i].alphas[1] > 0
              ? -40
              : 0;
      }
      p.fill(
        lines[i].widths[1] > 0 ? [0, 0, 0, lines[i].alphas[1]] : [0, 0, 0, 0]
      );
      lines[i].translate[1] = Math.random() < 0.0005 ? lineSkewWidth / 2 : 0;
      p.quad(
        lineSkewWidth -
        (lineSkewWidth / 4) * 1 +
        lines[i].translate[1] +
        horizontalGlitch,
        lineHeight / 4,
        lineSkewWidth -
        (lineSkewWidth / 4) * 1 +
        lines[i].widths[1] +
        lines[i].translate[1] +
        horizontalGlitch,
        lineHeight / 4,
        (lineSkewWidth / 4) * 2 +
        lines[i].widths[1] +
        lines[i].translate[1] +
        horizontalGlitch,
        (lineHeight / 4) * 2,
        (lineSkewWidth / 4) * 2 + lines[i].translate[1] + horizontalGlitch,
        (lineHeight / 4) * 2
      );
      lines[i].colors[2] = lineColor(
        backgroundImage.get(
          (lines[i].vector.x +
            x2 * direction -
            (p.width - imageWidth / imageScale) / 2) *
          imageScale +
          ((lineSkewWidth / 4) * 2 - lineSkewWidth / 8) +
          x,
          lines[i].vector.y * imageScale +
          ((lineHeight / 4) * 3 - lineHeight / 8)
        )
      );
      if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.02;
      if (lines[i].visible[0]) {
        lines[i].widths[2] +=
          !!lines[i].colors[2][3] && lines[i].widths[2] < line2Width
            ? 5
            : !lines[i].colors[2][3] && lines[i].widths[2] > 0
              ? -5
              : 0;
      }
      if (lines[i].visible[0]) {
        lines[i].alphas[2] +=
          !!lines[i].colors[2][3] && lines[i].alphas[2] < 255
            ? 40
            : !lines[i].colors[2][3] && lines[i].alphas[2] > 0
              ? -40
              : 0;
      }
      p.fill(
        lines[i].widths[2] > 0 ? [0, 0, 0, lines[i].alphas[2]] : [0, 0, 0, 0]
      );
      lines[i].translate[2] = Math.random() < 0.0005 ? lineSkewWidth / 2 : 0;
      p.quad(
        lineSkewWidth -
        (lineSkewWidth / 4) * 2 +
        lines[i].translate[2] +
        horizontalGlitch,
        (lineHeight / 4) * 2,
        lineSkewWidth -
        (lineSkewWidth / 4) * 2 +
        lines[i].widths[2] +
        lines[i].translate[2] +
        horizontalGlitch,
        (lineHeight / 4) * 2,
        lineSkewWidth / 4 +
        lines[i].widths[2] +
        lines[i].translate[2] +
        horizontalGlitch,
        (lineHeight / 4) * 3,
        lineSkewWidth / 4 + lines[i].translate[2] + horizontalGlitch,
        (lineHeight / 4) * 3
      );
      lines[i].colors[3] = lineColor(
        backgroundImage.get(
          (lines[i].vector.x +
            x2 * direction -
            (p.width - imageWidth / imageScale) / 2) *
          imageScale +
          ((lineSkewWidth / 4) * 1 - lineSkewWidth / 8) +
          x,
          lines[i].vector.y * imageScale +
          ((lineHeight / 4) * 4 - lineHeight / 8)
        )
      );
      if (!lines[i].visible[0]) lines[i].visible[0] = Math.random() < 0.02;
      if (lines[i].visible[0]) {
        lines[i].widths[3] +=
          !!lines[i].colors[3][3] && lines[i].widths[3] < line2Width
            ? 5
            : !lines[i].colors[3][3] && lines[i].widths[3] > 0
              ? -5
              : 0;
      }
      if (lines[i].visible[0]) {
        lines[i].alphas[3] +=
          !!lines[i].colors[3][3] && lines[i].alphas[3] < 255
            ? 40
            : !lines[i].colors[3][3] && lines[i].alphas[3] > 0
              ? -40
              : 0;
      }
      p.fill(
        lines[i].widths[3] > 0 ? [0, 0, 0, lines[i].alphas[3]] : [0, 0, 0, 0]
      );
      lines[i].translate[3] = Math.random() < 0.0005 ? lineSkewWidth / 2 : 0;
      p.quad(
        lineSkewWidth -
        (lineSkewWidth / 4) * 3 +
        lines[i].translate[3] +
        horizontalGlitch,
        (lineHeight / 4) * 3,
        lineSkewWidth -
        (lineSkewWidth / 4) * 3 +
        lines[i].widths[3] +
        lines[i].translate[3] +
        horizontalGlitch,
        (lineHeight / 4) * 3,
        lines[i].widths[3] + lines[i].translate[3] + horizontalGlitch,
        lineHeight,
        0 + lines[i].translate[3] + horizontalGlitch,
        lineHeight
      );
      lines[i].colors[4] = lineColor(
        backgroundImage.get(
          (lines[i].vector.x +
            x2 * direction -
            (p.width - imageWidth / imageScale) / 2) *
          imageScale +
          ((lineSkewWidth / 4) * 4 - lineSkewWidth / 8) +
          x,
          lines[i].vector.y * imageScale +
          ((lineHeight / 4) * 1 - lineHeight / 8)
        )
      );
      if (!lines[i].visible[4]) lines[i].visible[4] = Math.random() < 0.1;
      if (lines[i].visible[4])
        lines[i].widths[4] += lines[i].widths[4] < lineWidth ? 3 : 0;
      if (lines[i].visible[4])
        lines[i].alphas[4] += lines[i].alphas[4] < 255 ? 30 : 0;
      p.fill(
        lines[i].widths[4] > 0 ? [0, 0, 0, lines[i].alphas[4]] : [0, 0, 0, 0]
      );
      lines[i].translate[4] = Math.random() < 0.0002 ? lineSkewWidth / 2 : 0;
      p.quad(
        lineSkewWidth + horizontalGlitch + lines[i].translate[4],
        0,
        lineSkewWidth +
        lines[i].widths[4] +
        horizontalGlitch +
        lines[i].translate[4],
        0,
        lines[i].widths[4] + horizontalGlitch + lines[i].translate[4],
        lineHeight,
        0 + horizontalGlitch + lines[i].translate[4],
        lineHeight
      );
      p.pop();
    }
    //p.quad(50, 0, 63, 0, 13, 185, 0, 185);
  };

  p.preload = () => {
    backgroundImage = p.loadImage(a);
  };

  const lineColor = (color) => {
    color[3] = color[3] ? 255 : 0;
    return color;
  };

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth, p.windowHeight);
    scale = 850 / p.windowHeight;
    lineWidth = 10 / scale;
    line2Width = 35 / scale;
    lineHeight = 184 / scale;
    lineSkewWidth = 50 / scale;
    setupLines();
  };
}
