/* eslint-disable no-unused-vars */
import p5 from "p5";
import a from "../assets/a.png";

export default function sketch(p) {
  let canvas;
  var locs = [];
  let backgroundImage;
  let lineWidth = 38;
  let lineHeight = 184;
  let lineSkewWidth = 50;
  let imageWidth = 1920;
  let imageHeight = 1080;
  let imageScale;
  let anim = 0;
  let direction = 1;

  p.setup = () => {
    canvas = p.createCanvas(p.windowWidth + 320, p.windowHeight + 320);
    imageScale = imageHeight / p.height;
    //p.noLoop();
    backgroundImage.loadPixels();
    //p.pixelDensity(0.8);
    var res = 180;
    var countX = p.ceil((p.width * 3) / (res * 0.315));
    var countY = p.ceil(p.height / res);

    for (var j = 0; j < countY; j++) {
      for (var i = 0; i < countX; i++) {
        locs.push(new p5.Vector(res * (i - countX / 3) * 0.315, res * j));
      }
    }

    p.fill(0);
    p.noStroke();
    /*
    p.noFill();
    p.stroke(0, 0, 0);
    p.strokeWeight(13);
    */
  };

  p.draw = () => {
    anim = (p.width / 2 - p.mouseX) * 0.1;
    p.background(230, 230, 230);
    p.smooth();
    for (var i = locs.length - 1; i >= 0; i--) {
      //console.log(!!locs[i + 1], locs[i + 1].y, locs[i].y);
      if (!!locs[i + 1] && locs[i + 1].y !== locs[i].y) {
        direction *= -1;
        //console.log(direction);
      }
      p.push();
      p.translate(...[locs[i].x + anim * direction, locs[i].y]);
      //console.log(locs[i].y, direction);
      //p.rotate(0.261799);
      //p.line(0, 0, 0, lineSize);
      p.fill(
        noAlpha(
          backgroundImage.get(
            locs[i].x * imageScale +
            ((lineSkewWidth / 4) * 4 - lineSkewWidth / 8) +
            anim,
            locs[i].y * imageScale + ((lineHeight / 4) * 1 - lineHeight / 8)
          )
        )
      );
      p.quad(50, 0, 88, 0, 76, 46, 38, 46);
      p.fill(
        noAlpha(
          backgroundImage.get(
            locs[i].x * imageScale +
            ((lineSkewWidth / 4) * 3 - lineSkewWidth / 8) +
            anim,
            locs[i].y * imageScale + ((lineHeight / 4) * 2 - lineHeight / 8)
          )
        )
      );
      p.quad(38, 46, 76, 46, 63, 92, 25, 92);
      p.fill(
        noAlpha(
          backgroundImage.get(
            locs[i].x * imageScale +
            ((lineSkewWidth / 4) * 2 - lineSkewWidth / 8) +
            anim,
            locs[i].y * imageScale + ((lineHeight / 4) * 3 - lineHeight / 8)
          )
        )
      );
      p.quad(25, 92, 63, 92, 51, 138, 13, 138);
      p.fill(
        noAlpha(
          backgroundImage.get(
            locs[i].x * imageScale +
            ((lineSkewWidth / 4) * 1 - lineSkewWidth / 8) +
            anim,
            locs[i].y * imageScale + ((lineHeight / 4) * 4 - lineHeight / 8)
          )
        )
      );
      p.quad(13, 138, 51, 138, 38, 184, 0, 184);
      p.fill(0);
      p.quad(50, 0, 64, 0, 14, 184, 0, 184);
      p.pop();
    }
    //p.quad(50, 0, 63, 0, 13, 185, 0, 185);
  };

  p.preload = () => {
    backgroundImage = p.loadImage(a);
  };

  const noAlpha = (color) => {
    color[3] = color[3] ? 255 : 0;
    return color;
  };
}
